enum ZIDXFormFieldType{
    EMAIL,
    URL,
    HIDDEN,
    SUBMIT,
    SEPARATOR,
    RADIO,
    TEXT,
    HTML,
    CUSTOM,
    PHOTO_LIBRARY, // Not implemented yet
    PASSWORD,
    DATE,
    BOOLEAN,
    CHECKBOX,
    TEXTAREA,
    SELECT,
    BUTTON,
    IMAGE,
    FILE
}